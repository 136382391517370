<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h4 class="mb-0 py-3 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column max-h-700px scroll-y mx-5 py-7">
        <form class="row ma-0">
          <div
            class="col-12 col-sm-6 col-md-4 h-75px"
            v-if="!$store.getters.isClient"
          >
            <v-autocomplete
              v-model="formData.warehouse_id"
              label="Warehouse"
              :items="warehouses"
              item-text="text"
              item-value="index"
              dense
              clearable
              outlined
              hide-no-data
              hide-selected
              :error-messages="warehouseIdErrors"
              @blur="$v.formData.warehouse_id.$touch()"
              @change="handleChangeRuleType"
            />
          </div>
          <div
            class="col-12 col-sm-6 col-md-4 h-75px"
            v-if="!$store.getters.isClient"
          >
            <v-autocomplete
              v-model="formData.customer_id"
              label="Customer"
              :items="customers"
              item-text="text"
              item-value="index"
              dense
              clearable
              outlined
              hide-no-data
              hide-selected
              :error-messages="customerIdErrors"
              @blur="$v.formData.customer_id.$touch()"
            />
          </div>
          <div
            class="col-12 col-sm-6 col-md-4 h-75px"
            :class="{ 'col-12 col-sm-6': $store.getters.isClient }"
          >
            <v-autocomplete
              v-model="formData.sku_type_id"
              label="SKU types"
              :items="skuTypes"
              item-text="text"
              item-value="index"
              dense
              clearable
              outlined
              hide-no-data
              hide-selected
              :error-messages="skuTypeIdErrors"
              @blur="$v.formData.sku_type_id.$touch()"
            />
          </div>
          <div
            class="col-12 col-sm-6 col-md-4 h-75px"
            :class="{ 'col-12 col-sm-6': $store.getters.isClient }"
            v-if="formData.sku_type_id !== 3"
          >
            <!-- <v-autocomplete
              v-model="formData.category_id"
              label="SKU Category"
              :items="skuCategories"
              item-text="text"
              item-value="index"
              :disabled="formData.sku_type_id !== 2"
              dense
              outlined
              clearable
              deletable-chips
              hide-no-data
              hide-selected
              :error-messages="categoryIdErrors"
              @blur="$v.formData.category_id.$touch()"
              @change="loadSubcategoriesData"
            /> -->
            <v-autocomplete
              v-model="formData.category_id"
              label="SKU Category"
              :items="skuCategories"
              item-text="text"
              item-value="index"
              :disabled="formData.sku_type_id !== 2"
              dense
              outlined
              clearable
              deletable-chips
              hide-no-data
              hide-selected
            />
          </div>
          <div
            class="col-12 col-sm-6 col-md-4 h-75px"
            :class="{ 'col-12 col-sm-6': $store.getters.isClient }"
            v-if="formData.sku_type_id !== 3"
          >
            <v-autocomplete
              v-model="formData.sku_sub_categories"
              label="SKU Sub-category"
              :items="sub_categories"
              item-text="text"
              item-value="index"
              :disabled="formData.sku_type_id !== 2 || !sub_categories"
              dense
              outlined
              clearable
              multiple
              small-chips
              deletable-chips
              hide-no-data
              hide-selected
            />
          </div>
          <div
            v-else
            class="col-12 col-sm-6 col-md-4 h-75px"
            :class="{ 'col-12 col-sm-6': $store.getters.isClient }"
          >
            <v-combobox
              v-model="formData.skus"
              label="SKUS"
              :items="skus"
              item-text="systemCode"
              item-value="skuId"
              dense
              outlined
              clearable
              multiple
              small-chips
              deletable-chips
              hide-no-data
              hide-selected
              :loading="isLoading"
              :search-input.sync="search"
              :error-messages="sksuIdErrors"
              @blur="$v.formData.skus.$touch()"
            />
          </div>
          <div class="col-12 col-sm-6 col-md-4 h-75px">
            <v-autocomplete
              v-model="formData.brand_id"
              label="Brands"
              :items="brands"
              item-text="text"
              item-value="index"
              dense
              :disabled="formData.sku_type_id !== 2"
              outlined
              clearable
              deletable-chips
              hide-no-data
              hide-selected
            />
          </div>
          <div class="col-12 col-sm-4 h-75px">
            <v-text-field
              v-model="formData.size"
              :disabled="formData.sku_type_id !== 2"
              label="Size"
              dense
              outlined
              clearable
            />
          </div>
          <div class="col-12 col-sm-4 h-75px">
            <v-text-field
              v-model="formData.color"
              :disabled="formData.sku_type_id !== 2"
              label="Color"
              dense
              outlined
              clearable
            />
          </div>

          <div class="col-12 col-sm-4 h-75px">
            <v-text-field
              v-model="formData.design_number"
              label="Design"
              :disabled="formData.sku_type_id !== 2"
              dense
              outlined
              clearable
            />
          </div>
          <div class="col-12 col-sm-4 h-75px">
            <v-text-field
              v-model="formData.name"
              label="Name"
              dense
              outlined
              clearable
              :error-messages="nameErrors"
              @blur="$v.formData.name.$touch()"
            />
          </div>
          <div class="col-12 col-sm-4 h-75px">
            <v-text-field
              v-model="formData.priority"
              label="Priority"
              type="number"
              @change="() => validateMinValue('priority', 0, 'formData')"
              dense
              outlined
              clearable
              :error-messages="priorityErrors"
              @blur="$v.formData.priority.$touch()"
            />
          </div>
          <div class="col-12 col-sm-4 h-75px">
            <v-autocomplete
              v-model="formData.status"
              label="Status"
              :items="statusValues"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="statusErrors"
              @blur="$v.formData.status.$touch()"
            />
          </div>
          <div class="col-12 col-sm-4 h-75px">
            <v-autocomplete
              v-model="formData.rule_type_id"
              :disabled="!formData.warehouse_id"
              label="Rule type"
              :items="ruleTypes"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              @change="handleChangeRuleType"
              :error-messages="typeErrors"
              @blur="$v.formData.rule_type_id.$touch()"
            />
          </div>
          <div
            class="col-12 col-sm-4 h-75px"
            v-if="formData.warehouse_id && formData.rule_type_id === 3"
          >
            <v-autocomplete
              v-model="formData.rule_based_template_id"
              label="Rule"
              :items="ruleBasedTemplates"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="typeErrors"
            />
          </div>
          <div class="col-12 col-sm-4 h-75px">
            <div class="putaway-is-overwrite d-flex align-start ma-0 py-0 px-3">
              <h5 class="pr-4 pt-3 font-weight-regular">Overwriting Allowed</h5>
              <v-switch
                v-model="formData.is_overwrite"
                hide-details
                dense
                inset
                :error-messages="overWritingErrors"
                @blur="$v.formData.is_overwrite.$touch()"
                color="purple darken-3"
              ></v-switch>
            </div>
          </div>
        </form>
        <div v-if="formData.rule_type_id === 2" class="mt-4 pa-0 w-100">
          <SuggestionsDatatable />
        </div>

        <!--        <div v-if="formData.rule_type_id === 3" class="mt-4 pa-0 w-100">-->
        <!--          <RulesDatatable />-->
        <!--        </div>-->

        <!--begin::Actions-->
        <div class="mb-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {
  GET_DATA,
  SET_RULES,
  SET_SUGGESTIONS,
  GET_TEMPLATE_DATA,
} from "@/core/services/store/putawayRules.module";
// import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import { getToken } from "@/core/services/jwt.service";
import SuggestionsDatatable from "@/own/components/warehouseManagement/putawayRules/SuggestionsDatatable";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin, fieldValueValidation],
  components: { SuggestionsDatatable },
  validations: {
    formData: {
      warehouse_id: { required },
      customer_id: { required },
      sku_type_id: { required },
      name: { required },
      status: { required },
      rule_type_id: { required },
      priority: { required },
      is_overwrite: { required },
      // category_id: {
      //   required: requiredIf(function () {
      //     return this.formData.sku_type_id === 2;
      //   }),
      // },
      skus: {
        required: requiredIf(function () {
          return this.formData.sku_type_id === 3;
        }),
      },
    },
  },
  data: () => ({
    dialog: false,
    entries: [],
    search: null,
    isLoading: false,
    formData: {
      id: null,
      warehouse_id: null,
      customer_id: null,
      sku_type_id: null,
      sku_categories: null,
      sku_sub_categories: null,
      skus: null,
      name: null,
      priority: null,
      status: null,
      rule_type_id: null,
      is_overwrite: false,
      rule_based_template_id: null,
      brand_id: null,
      size: null,
      color: null,
      design_number: null,
    },
    sub_categories: null,
    ruleBasedTemplates: [],
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
        this.$v.$reset();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    //TODO::
    resetFormData() {
      this.formData = {
        id: null,
        warehouse_id: null,
        customer_id: null,
        sku_type_id: null,
        sku_categories: null,
        sku_sub_categories: null,
        skus: null,
        name: null,
        priority: null,
        status: null,
        rule_type_id: null,
        is_overwrite: false,
        rule_based_template_id: null,
        brand_id: null,
        size: null,
        color: null,
        design_number: null,
      };
      this.sub_categories = null;
    },
    loadDataFromServer() {
      this.$store.dispatch(GET_DATA);
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        this.pageLoader(true);
        ApiService.post("/warehouse_management/putaway_rules/edit", sendData)
          .then(({ data }) => {
            this.formData = data.rule;
            this.ruleBasedTemplates = data.rule_based_templates;
            this.$store.commit(SET_SUGGESTIONS, data.rule.suggested_rules);
            this.$store.commit(SET_RULES, data.rule.rule_based);
            this.loadSubcategoriesData();
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      } else {
        this.$store.commit(SET_SUGGESTIONS, []);
        this.$store.commit(SET_RULES, []);
      }
    },
    loadSubcategoriesData() {
      if (this.formData.category_id) {
        this.pageLoader(true);
        ApiService.post("/skus/sub-categories", {
          parent_id: this.formData.category_id,
        })
          .then(({ data }) => {
            this.sub_categories = data.sub_categories;
          })
          .finally(() => {
            this.pageLoader(false);
          });
      }
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    async handleChangeRuleType() {
      if (this.formData.warehouse_id && this.formData.rule_type_id === 3) {
        await this.$store.dispatch(GET_TEMPLATE_DATA, {
          warehouse_id: this.formData.warehouse_id,
        });
        this.getRuleBasedTemplates();
      }
    },
    getRuleBasedTemplates() {
      this.ruleBasedTemplates =
        this.$store.getters.getPUTAWAYRULESServerData.rule_based_templates;
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      const skus =
        this.formData.sku_type_id === 3
          ? this.formData.skus.map((item) => item.skuId)
          : null;
      if (this.formData.sku_type_id !== 2) {
        this.formData = {
          sku_type_id: null,
          sku_sub_categories: null,
          brand_id: null,
          size: null,
          color: null,
          design_number: null,
          ...this.formData,
        };
      }
      let sendData = {
        ...this.formData,
        skus,
      };

      if (this.$store.getters.getPUTAWAYRULESSuggestions.length) {
        const suggestions = [...this.$store.getters.getPUTAWAYRULESSuggestions];
        suggestions.forEach((suggestion) => {
          if (suggestion.zones)
            suggestion.zones = suggestion.zones.map((item) => item.index);
          if (suggestion.locations)
            suggestion.locations = suggestion.locations.map(
              (item) => item.index
            );
        });

        sendData.suggested_rules = suggestions;
        sendData.rule_based = [];
      } else {
        sendData.rule_based = this.$store.getters.getPUTAWAYRULESRules;
        sendData.suggested_rules = [];
      }

      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      ApiService.post(
        `/warehouse_management/putaway_rules/${actionUrl}`,
        sendData
      )
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    onStoreTemplate() {
      this.handleChangeRuleType();
    },

    async getData(value) {
      let requestBody = { q: value };
      if (this.userId) requestBody.client = this.userId;

      await fetch(`${process.env.VUE_APP_BASE_URL}/skus/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(requestBody),
      })
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.skus;
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.formData.warehouse_id = this.selectedWarehouse;
  },
  computed: {
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    userId: function () {
      return this.$store.getters.isClient
        ? this.$store.getters.currentUser.data.id
        : this.formData.customer_id;
    },
    warehouses: function () {
      return this.$store.getters.getPUTAWAYRULESServerData.warehouses;
    },
    customers: function () {
      return this.$store.getters.getPUTAWAYRULESServerData.customers;
    },
    skuTypes: function () {
      return this.$store.getters.getPUTAWAYRULESServerData.sku_types;
    },
    skuCategories: function () {
      return this.$store.getters.getPUTAWAYRULESServerData.sku_categories;
    },
    brands: function () {
      return this.$store.getters.getPUTAWAYRULESServerData.brands;
    },
    statusValues: function () {
      return [
        {
          index: false,
          text: "Disabled",
        },
        {
          index: true,
          text: "Enabled",
        },
      ];
    },
    ruleTypes: function () {
      return this.$store.getters.getPUTAWAYRULESServerData.rule_types;
    },
    skus: function () {
      return this.entries;
    },
    warehouseIdErrors: function () {
      return this.handleFormValidation("warehouse_id");
    },
    // categoryIdErrors: function () {
    //   return this.handleFormValidation("category_id");
    // },
    sksuIdErrors: function () {
      return this.handleFormValidation("skus");
    },
    customerIdErrors: function () {
      return this.handleFormValidation("customer_id");
    },
    skuTypeIdErrors: function () {
      return this.handleFormValidation("sku_type_id");
    },
    nameErrors: function () {
      return this.handleFormValidation("name");
    },
    priorityErrors: function () {
      return this.handleFormValidation("priority");
    },
    statusErrors: function () {
      return this.handleFormValidation("status");
    },
    typeErrors: function () {
      return this.handleFormValidation("rule_type_id");
    },
    overWritingErrors: function () {
      return this.handleFormValidation("is_overwrite");
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  watch: {
    search(val) {
      this.isLoading = true;
      this.getData(val);
    },
  },
};
</script>

<style lang="scss">
.putaway-is-overwrite {
  .v-input--selection-controls {
    margin-top: 6px !important;
  }
}
</style>
